import { Link } from "gatsby";
import React from "react";
import tags from "./tags";
import cms from "./cms/cms";

const Tag = ({ tag }) => {
  if (tags[tag]) {
    const name = tags[tag].name
    const logo = tags[tag].logo

    return <Link
      to={`/tags/${tag}`}
      className="mr-2 px-3 py-1 inline-flex leading-5 rounded-full bg-gray-800 hover:bg-gray-700 font-semibold flex-shrink-0 border border-gray-600"
    >
      {
        logo ?
          <img
            className="h-5 w-auto"
            src={logo}
            alt={name} /> : name
      }
    </Link>
  } else return <></>
}

const Tags = ({ jobTags }) => jobTags && cms.keyword === "Scala" ?
  <div className="mt-2 sm:mt-4 flex items-center leading-5 text-gray-200 overflow-x-auto">
    {jobTags.map(tag => <Tag key={tag} tag={tag} />)}
  </div> : <></>

const TagLight = ({ tag }) => {
  if (tags[tag]) {
    const name = tags[tag].name
    const logo = tags[tag].logo

    return <Link
      to={`/tags/${tag}`}
      className="mr-2 px-3 py-1 inline-flex leading-5 rounded-full bg-gray-100 hover:bg-gray-50 font-semibold flex-shrink-0 border border-gray-200"
    >
      {
        logo ?
          <img
            className="h-5 w-auto"
            src={logo}
            alt={name} /> : name
      }
    </Link>
  } else return <></>
}

export const TagsLight = ({ jobTags }) => jobTags && cms.keyword === "Scala" ?
  <div className="mt-2 sm:mt-4 flex items-center leading-5 text-gray-500 overflow-x-auto">
    {jobTags.map(tag => <TagLight key={tag} tag={tag} />)}
  </div> : <></>

export default Tags;